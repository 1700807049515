<template>
    <v-sheet>
        <features-first-view-section></features-first-view-section>
        <data-types-section></data-types-section>
        <feature-details-section></feature-details-section>
        <call-to-action-section></call-to-action-section>
        <footer-section></footer-section>
    </v-sheet>
</template>
<script>
import FeaturesFirstViewSection from '../components/FeaturesFirstViewSection.vue'
import FeatureDetailsSection from '../components/FeatureDetailsSection.vue'
import DataTypesSection from '../components/DataTypesSection.vue'
import FooterSection from '../components/FooterSection.vue'
import CallToActionSection from '../components/CallToActionSection.vue'

export default {
    components: {
        FeaturesFirstViewSection,
        FeatureDetailsSection,
        DataTypesSection,
        FooterSection,
        CallToActionSection,
    }
}
</script>