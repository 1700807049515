<template>
    <page-section id="fv" :style="styles.fv">
        <div>
            <div id="title" :style="styles.title">
                Tuttiの機能
            </div>

            <img src="@/assets/web-figures/fv.png" id="fig-fv" :style="styles.figFv" />

            <contact-button class="mt-12" :style="styles.contactButton"></contact-button>
        </div>
    </page-section>
</template>
<script>
import ContactButton from './ContactButton.vue'
import PageSection from './PageSection.vue'

export default {
  components: { PageSection, ContactButton },
  props: {
      logoSrcs: { type: Array, default: () => ([]) },
  },
  computed: {
      styles() {
          return {
            fv: {
                paddingTop: this.$vuetify.breakpoint.forPC ? '80px!important' : '30px!important',
                height: this.$vuetify.breakpoint.forPC ? '350px' : '250px'
            },
            title: {
                fontSize: this.$vuetify.breakpoint.forPC ? '36px' : '24px'
            },
            subtitle: {
                fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px'
            },
            figFv: this.$vuetify.breakpoint.forPC
                ? {
                    position: 'absolute',
                    bottom: '-150px',
                    left: '400px',
                    right: '0',
                    margin: 'auto'
                }
                : {
                    width: '95%',
                    maxWidth: '400px',
                    position: 'absolute',
                    bottom: '-70px',
                    left: '0',
                    right: '0',
                    margin: 'auto'
                },
            contactButton: this.$vuetify.breakpoint.forPC
                ? {}
                : {
                    position: 'absolute',
                    bottom: '-150px',
                    left: '0',
                    right: '0',
                    margin: 'auto'
                }
          }
      }
  }
}
</script>
<style scoped>
#fv {
    background-image: url('~@/assets/web-figures/fv-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    margin-bottom: 180px;
    position: relative;
}
#title {
    font-weight: 700;
}
#fig-fv {
    position: absolute;
}
</style>
