<template>
    <v-row :style="styles.wrapper" :class="flexDirection" align="center">
        <v-col :cols="$vuetify.breakpoint.forPC ? 6 : 12" :class="['feature-detail', bodyColumnPadding]">
            <p id="title" :style="styles.title" v-html="title" />
            <div>
                <template v-for="item, i in body">
                    <div :key="`feature-detail-${index}-${i}`">
                        <text-horizontal-line
                            v-if="'subtitle' in item"
                            left
                            :text="item.subtitle"
                            :font-style="styles.subtitle"
                        ></text-horizontal-line>
                        <p v-html="item.body" :style="styles.body"></p>
                    </div>
                </template>
            </div>
            <div class="background" :style="styles.background">
            </div>
        </v-col>
        <v-col
            :cols="$vuetify.breakpoint.forPC ? 6 : 12"
            :class="['figures', 'text-center', figureColumnPadding]"
            :style="styles.figures"
        >
            <div v-for="src, i in figSrcs" :key="`feature-detail-figure-${index}-${i}`">
                <img :src="src" class="figure" />
            </div>
        </v-col>
    </v-row>
</template>
<script>
import TextHorizontalLine from "./TextHorizontalLine.vue"

export default {
    components: { TextHorizontalLine },
    props: {
        index: { type: Number, default: 0 },
        title: { type: String, default: '' },
        body: { type: Array, default: () => ([]) },
        figSrcs: { type: Array, default: () => ([]) }
    },
    computed: {
        styles() {
            return {
                wrapper: {
                    paddingTop: this.$vuetify.breakpoint.forPC ? '70px' : '20px',
                },
                title: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '24px' : '18px',
                },
                subtitle: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '18px' : '16px',
                },
                body: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px',
                },
                figures: {
                    paddingTop: this.$vuetify.breakpoint.forPC ? undefined : '30px',
                },
                figure: {
                    maxHeight: this.$vuetify.breakpoint.forPC ? undefined : '200px',
                    maxWidth: this.$vuetify.breakpoint.forPC ? undefined : '90%',
                },
                background: {
                    width: this.$vuetify.breakpoint.forPC ? '10000px' : 'calc(100% + 36px)',
                    left: this.$vuetify.breakpoint.forPC ? '-4000px' : '-18px',
                },
            }
        },
        flexDirection() {
            return this.index%2==0 ? 'flex-row' : 'flex-row-reverse';
        },
        bodyColumnPadding() {
            return this.$vuetify.breakpoint.forPC ? (this.index%2==0 ? 'pr-12' : 'pl-12') : '';
        },
        figureColumnPadding() {
            return this.$vuetify.breakpoint.forPC ? (this.index%2==0 ? 'pl-12' : 'pr-12') : '';
        }
    }
}
</script>
<style scoped>
#title {
    font-weight: 700;
}
.feature-detail {
    padding-top: 30px;
    padding-bottom: 20px;
    position: relative;
    z-index: 0;
}
.background {
    background-color: #fdd835;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
}
.figures {
    position: relative;
    z-index: 0;
}
img.figure {
    width: 100%;
    max-width: 400px;
}
</style>