<template>
    <page-section id="section">
        <page-section-title
            title="Tuttiでラベリング可能なデータの種類"
            subtitle="Tuttiでは、様々なラベリングシステムの構築にお使いいただける<br>
                      数々のページテンプレートと、それらを自由にカスタマイズ頂ける環境をご提供します。<br>
                      複数のデータ種類を扱うラベリングのフローを設計することも可能です。"
        ></page-section-title>
        
        <v-row class="justify-center">
            <v-col :cols="$vuetify.breakpoint.forPC ? 4 : 6" style="text-align:center;">
                <img class="data-type-fig" :style="styles.dataTypeFig" src="@/assets/web-figures/data-type-01.png" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.forPC ? 4 : 6" style="text-align:center;">
                <img class="data-type-fig" :style="styles.dataTypeFig" src="@/assets/web-figures/data-type-02.png" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.forPC ? 4 : 6" style="text-align:center;">
                <img class="data-type-fig" :style="styles.dataTypeFig" src="@/assets/web-figures/data-type-03.png" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.forPC ? 4 : 6" style="text-align:center;">
                <img class="data-type-fig" :style="styles.dataTypeFig" src="@/assets/web-figures/data-type-04.png" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.forPC ? 4 : 6" style="text-align:center;">
                <img class="data-type-fig" :style="styles.dataTypeFig" src="@/assets/web-figures/data-type-05.png" />
            </v-col>
        </v-row>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from './PageSectionTitle.vue'
export default {
    components: {
      PageSection,
      PageSectionTitle,
    },
    computed: {
        styles() {
            return {
                dataTypeFig: {
                    maxWidth: this.$vuetify.breakpoint.forPC ? undefined : '250px',
                },
            }
        }
    }
}
</script>
<style scoped>
#section {
    background-image: url('~@/assets/web-figures/background-grid.png');
    background-repeat: repeat;
    font-size: 16px;
}
.data-type-fig {
    width: 100%;
}
</style>
